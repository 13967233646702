import React from 'react'

import HiddenLayout from '../components/HiddenLayout'
import '../components/fullPageCss.sass'
import storage from '../lib/storage'

export default class AdminNavbar extends React.Component {
  state = {}
  componentDidMount() {
    this.setState({
      token: storage.get('token'),
    })
  }
  render() {
    const {
      pageContext: { entryPoint },
    } = this.props

    if (!this.state.token) {
      return <div />
    }

    const link = `https://insights.collegepulse.com/vault/question/${entryPoint}?report&token=${this.state.token}`
    return (
      <HiddenLayout>
        <iframe
          title="brand_infogram"
          src={link}
          className="full-page-iframe noHeader loading-gif-background"
        />
      </HiddenLayout>
    )
  }
}
